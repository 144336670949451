<template>
    <div class="page">
        <banner></banner>
        <div class="tuijian-wrap w-1300">
            <div class="center-text">
                <p></p>
                <span>本地商务服务</span>
            </div>
            <div class="tuijian-list">
                <div class="tuijian-item" v-for="(item,index) in fenleiList" :key="index" @click="goTo('shangwu_list',{id:item.id})">
                    <div class="tuijian-top">
                        <div class="tuijian-img"><img :src="item.banner_url" alt=""></div>
                        <div class="tuijian-p">{{item.name}}</div>
                    </div>
                </div>
            </div>

            <div class="center-text">
                <p></p>
                <span>列表</span>
            </div>
            <div class="fuwu-wrap">
                <!-- <el-tabs v-model="activeName" @tab-click="handleClick">
                    <el-tab-pane label="法律服务" name='1'></el-tab-pane>
                    <el-tab-pane label="资讯服务" name="2"></el-tab-pane>
                    <el-tab-pane label="融资服务" name="3"></el-tab-pane>
                    <el-tab-pane label="企业宣传片" name="4"></el-tab-pane>
                </el-tabs> -->
                <div class="fuwu-tab-wrap">
                    <div class="fuwu-tab" :class="{active:id==item.id}"  v-for="(item,index) in fenleiList" :key="index" @click="tabClick(item.id)">{{item.name}}</div>
                </div>
                <div class="fuwu-list">
                    <div class="fuwu-item" v-for="(item,index) in liebiaoList" :key="index">
                        <div class="fuwu-item-text">{{item.name}}</div>
                        <div class="fuwu-item-p" v-html="delLabel(item.content)">进口需求子分类介绍进口需求子分类介绍进口需求子分类介绍</div>
                        <div class="fuwu-item-bt" @click="goTo('shangwu_details',{id:item.id})">服务内容介绍</div>
                    </div>

                </div>
            </div>
            

        </div>

    </div>
</template>

<script>
    import { getplates,getplatexts } from "../../api/index"
    import banner from '../../components/banner.vue';
    export default {
        data() {
            return {
                fenleiList:[],
                id:'',
                liebiaoList:[]
                
            }
        },
        components:{
            banner
        },
        created() {
            this.getplates()
        },
        methods: {
            // 去除富文本所有标签
            delLabel(input){
                if (input!=null) {
                    return input.replace(/<\/?[^>]*>/g, ""); 
                }
            },
            // 跳转页面
            goTo(url,query) {
                this.$router.push({ 
                    path: url ,
                    query
                });
            },
            tabClick(id){
                this.id=id;
                this.getplatexts();
            },
            // 获取分类
            getplates(){
                getplates({
                    p_id:25
                }).then((res)=>{
                    console.log(res)
                    if (res.code==200) {
                        this.fenleiList=res.data
                        if (res.data.length>0) {
                            this.id=res.data[0].id
                            this.getplatexts()
                        }else{
                            this.liebiaoList=[]
                        }
                    }
                })

            },
            //获取列表
            getplatexts(){
                getplatexts({
                    plate_id:this.id
                }).then((res)=>{
                    // console.log(res)
                    if (res.code==200) {
                        this.liebiaoList=res.data.data
                    }
                })
            } 
            
        },
        
    }
</script>

<style lang="less" scoped>
/deep/ .el-tabs__item{color: #666666;font-size: 16px;}
/deep/ .el-tabs__item.is-active{color: #222;font-weight: bold;}
/deep/ .el-tabs__active-bar{background-color: #FF7048;height: 3px;}
/deep/ .el-tabs__nav-wrap::after{height: 1px;}
    .page{overflow: hidden;}
    .tuijian-wrap{
        margin: auto;
        .tuijian-list{
            margin-top: 40px;
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;
            .tuijian-item{
                // width: 18%;
                // margin-right: 2.5%;
                width: 15%;
                margin-right: 2%;
                padding: 0 10px;
                box-sizing: border-box;
                margin-bottom: 25px;
                cursor: pointer;
                .tuijian-top{
                    text-align: center;
                    padding: 35px 0;
                    .tuijian-img{
                        width: 40px;margin:auto;
                        img{width: 100%;}
                    }
                    .tuijian-p{color: #fff;font-size: 22px;font-weight: bold;margin-top: 20px;}
                }
                &:nth-child(6n+1){background: url('../../assets/images/jksctj1.png');}
                &:nth-child(6n+2){background: url('../../assets/images/jksctj2.png');}
                &:nth-child(6n+3){background: url('../../assets/images/jksctj3.png');}
                &:nth-child(6n+4){background: url('../../assets/images/jksctj4.png');}
                &:nth-child(6n+5){background: url('../../assets/images/jksctj5.png');}
                &:nth-child(6n+0){margin-right: 0;background: url('../../assets/images/jksctj6.jpg');}
            }
        }

        .fuwu-wrap{
            margin-top: 40px;
            .fuwu-tab-wrap{
                // padding: 0 30px;
                border-bottom: 1px solid #E9E9E9;
                display: flex;
                .fuwu-tab{
                    padding: 10px 0;
                    border-bottom: 3px solid #fff;
                    color: #666666;
                    font-size: 16px;
                    margin: 0 15px;
                    cursor: pointer;
                }
                .active{
                    color: #222222;
                    border-bottom: 3px solid #FF7048;
                    font-weight: bold;
                }
            }
            .fuwu-list{
                display: flex;
                flex-wrap: wrap;
                margin-top: 35px;
                margin-bottom: 70px;
                .fuwu-item{
                    width: 18.5%;
                    margin-right: 1.875%;
                    margin-bottom: 30px;
                    border: 1px solid #E8E8E8;
                    box-shadow: 0px 3px 9px 0px rgba(0, 0, 0, 0.16);
                    padding:  0 19px;
                    box-sizing: border-box;
                    text-align: center;
                    &:nth-child(5n+0){
                        margin-right: 0;
                    }
                    .fuwu-item-text{
                        color: #000;
                        font-size: 16px;
                        font-weight: bold;
                        margin-top: 25px;
                        overflow: hidden;
                        text-overflow: ellipsis;
                        display: -webkit-box;
                        -webkit-line-clamp: 1;
                        -webkit-box-orient: vertical;
                    }
                    .fuwu-item-p{
                        height: 40px;
                        color: #666;
                        font-size: 14px;
                        line-height: 20px;
                        margin: 15px 0;
                        overflow: hidden;
                        text-overflow: ellipsis;
                        display: -webkit-box;
                        -webkit-line-clamp: 2;
                        -webkit-box-orient: vertical;
                    }
                    .fuwu-item-bt{
                        width: 80%;
                        height: 33px;
                        border-radius: 15px;
                        background: linear-gradient(138deg, #CF694E, #FD9976);
                        margin: auto;
                        margin-bottom: 13px;
                        text-align: center;
                        line-height: 33px;
                        color: #fff;
                        font-size: 14px;
                        cursor: pointer;
                    }

                }
            }

        }

        
    }

</style>